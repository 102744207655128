<template>
  <div class="add-form">
    <a-steps v-model="stepActive" type="navigation" size="small" :style="stepStyle">
      <a-step title="Step 1" :description="$t('addRegistryStep.1')" disabled />
      <a-step title="Step 2" :description="$t('addRegistryStep.2')" disabled />
      <a-step title="Step 3" :description="$t('addRegistryStep.3')" disabled />
    </a-steps>

    <div class="registry-item-container" v-if="stepActive == 0">
      <div v-for="item in registryList" :key="item.kind" @click="selectRegistry(item.kind)">
        <div v-if="item.kind == 'RegistryAWS'">
          <div class="registry-item-title">Registry AWS</div>
          <img :src="aws" />
        </div>
        <div v-if="item.kind == 'RegistryDocker'">
          <div class="registry-item-title">Registry Docker</div>
          <img :src="docker" />
        </div>

        <div v-if="item.kind == 'RegistryGCP'">
          <div class="registry-item-title">Registry GCP</div>
          <img :src="gcp" />
        </div>

        <div v-if="item.kind == 'RegistryAliACR'">
          <div class="registry-item-title">Registry AliACR</div>
          <img :src="aliyun" />
        </div>

        <div v-if="item.kind == 'RegistryTencentTCR'">
          <div class="registry-item-title">Registry TencentTCR</div>
          <img :src="tencentcloud" />
        </div>

        <div v-if="item.kind == 'RegistryHuaweiSWR'">
          <div class="registry-item-title">Registry HuaweiSWR</div>
          <img :src="huaweicloud" />
        </div>

        <div v-if="item.kind == 'RegistryHarbor'">
          <div class="registry-item-title">Registry Harbor</div>
          <img :src="harbor" />
        </div>

        <div v-if="item.kind == 'RegistryHarborv2'">
          <div class="registry-item-title">Registry Harbor V2.0 +</div>
          <img :src="harbor" />
        </div>

        <div v-if="item.kind == 'RegistryUhub'">
          <div class="registry-item-title">Registry U hub</div>
          <img :src="ucloud" />
        </div>
      </div>
    </div>

    <div v-if="stepActive == 1">
      <registry-form :form="form" ref="registryForm" type="add">
        <template #button>
          <el-form-item>
            <el-button @click="addRegistry" type="primary" :loading="addRegistryLoading" plain>
              {{ $t("handle.addRegistry") }}
            </el-button>
            <el-button @click="toInit" type="primary" plain>{{ $t("handle.lastStep") }}</el-button>
          </el-form-item>
        </template>
      </registry-form>
    </div>
  </div>
</template>

<script>
import provider from "@/mixins/provider";
import { supportRegistries, addRegistry } from "api/registry";
import RegistryForm from "./Form.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    RegistryForm
  },

  mixins: [provider],
  data() {
    return {
      stepActive: 0,
      registryList: [],
      form: {
        name: "",
        kind: "",
        organizationUUID: "",
        host: "",
        regionID: "",
        accessKey: "",
        secretAccessKey: "",
        gcpConfJson: "",
        auth: false,
        username: "",
        password: "",
        email: "",
        ca: "",
        pullImageUser: "",
        pullImagePassword: "",
        repoNamespace: "",
        registryID: "",
        namespace: "",
        account: "",
        accountPassword: "",
        domainName: "",
        projectName: "",
        bindings: [],
        scheme: "https",
        repoName: "",
        publicKey: "",
        privateKey: "",
        projectId: "",
        apiHost: "api.ucloud.cn"
      },
      addRegistryLoading: false,
      stepStyle: {
        marginBottom: "20px",
        boxShadow: "0px -1px 0 0 #e8e8e8 inset"
      }
    };
  },
  methods: {
    supportRegistries() {
      supportRegistries().then(response => {
        if (response.code === 0) {
          this.registryList = response.data.items;
        }
      });
    },

    selectRegistry(value) {
      this.form.kind = value;
      this.stepActive++;
    },

    addRegistry() {
      this.$refs["registryForm"].$refs["form"].validate(valid => {
        if (valid) {
          this.addRegistryLoading = true;

          let form = cloneDeep(this.form);
          // this.form.organizationUUID = this.organization;
          form.organizationUUID = this.organization;

          if (this.$refs["registryForm"].providerRegistryBindings.length > 0) {
            // this.form.bindings = this.$refs["registryForm"].providerRegistryBindings.map(item => {
            //   return { providerUUID: item };
            // });

            form.bindings = this.$refs["registryForm"].providerRegistryBindings.map(item => {
              return { providerUUID: item };
            });
          }

          addRegistry(form).then(response => {
            this.addRegistryLoading = false;
            if (response.code === 0) {
              this.$router.push({ path: "/admin/registry/list" });
            }
          });
        }
      });
    },

    toInit() {
      this.stepActive--;
      this.form = {
        name: "",
        kind: "",
        organizationUUID: this.organization,
        host: "",
        regionID: "",
        accessKey: "",
        secretAccessKey: "",
        gcpConfJson: "",
        auth: false,
        username: "",
        password: "",
        email: "",
        ca: "",
        pullImageUser: "",
        pullImagePassword: "",
        repoNamespace: "",
        registryID: "",
        namespace: "",
        account: "",
        accountPassword: "",
        domainName: "",
        projectName: "",
        scheme: "https",
        repoName: "",
        publicKey: "",
        privateKey: "",
        projectId: "",
        apiHost: "api.ucloud.cn"
      };
    }
  },
  mounted() {
    this.supportRegistries();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.registry-item-container {
  @include flex(space-around);

  & > div {
    width: 30%;
    text-align: center;
    background-color: #f9fafb;
    margin-bottom: 20px;
    height: 160px;
    position: relative;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      box-shadow: $common-shadow;
      background-color: #fff;
      .registry-item-title {
        background-color: #b1c9e2;
      }
    }
  }

  .registry-item-title {
    line-height: 30px;
    background-color: #adbfd5;
    color: #fff;
    border-radius: 8px 8px 0 0;
    transition: 0.5s all ease;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
  }
}
</style>
